import { Navigate, Route, Routes } from "react-router-dom";
import HomePage from "pages/home";
import SalesPage from "pages/sales";
import SalesDetailPage from "pages/sales/detail";
import SalesNewPage from "pages/sales/new";
import SalesEditPage from "pages/sales/edit";
import SellPage from "pages/sell";
import BuyPage from "pages/buy";
import RoofPage from "pages/roof";
import FaqPage from "pages/faq";
import FaqNewPage from "pages/faq/new";
import FaqEditPage from "pages/faq/edit";
import FaqDetailPage from "pages/faq/detail";
import LoginPage from "pages/login";
import ProfilePage from "pages/profile";
import Test from "./Test";

interface RouterProps {
    isAuthenticated: boolean;
}

export default function Router({ isAuthenticated }: RouterProps) {
    return (
        <>
            <Routes>
                {isAuthenticated ? (
                    <>
                        <Route path="/" element={<HomePage />} />
                        <Route path="sales" element={<SalesPage />} />
                        <Route path="sales/:id" element={<SalesDetailPage />} />
                        <Route path="sales/new" element={<SalesNewPage />} />
                        <Route path="sales/edit/:id" element={<SalesEditPage />} />
                        <Route path="sell" element={<SellPage />} />
                        <Route path="buy" element={<BuyPage />} />
                        <Route path="roof" element={<RoofPage />} />
                        <Route path="faq" element={<FaqPage />} />
                        <Route path="faq/:id" element={<FaqDetailPage />} />
                        <Route path="faq/new" element={<FaqNewPage />} />
                        <Route path="faq/edit/:id" element={<FaqEditPage />} />
                        <Route path="profile" element={<ProfilePage />} />
                        <Route path="test" element={<Test />} />
                        <Route path="*" element={<Navigate replace to="/" />} />
                    </>
                ) : (
                    <>
                        <Route path="/" element={<HomePage />} />
                        <Route path="login" element={<LoginPage />} />
                        <Route path="sales" element={<SalesPage />} />
                        <Route path="sales/:id" element={<SalesDetailPage />} />
                        <Route path="sales/new" element={<SalesNewPage />} />
                        <Route path="sales/edit/:id" element={<SalesEditPage />} />
                        <Route path="sell" element={<SellPage />} />
                        <Route path="buy" element={<BuyPage />} />
                        <Route path="roof" element={<RoofPage />} />
                        <Route path="faq" element={<FaqPage />} />
                        <Route path="faq/:id" element={<FaqDetailPage />} />
                        <Route path="faq/new" element={<FaqNewPage />} />
                        <Route path="faq/edit/:id" element={<FaqEditPage />} />
                        <Route path="test" element={<Test />} />
                        <Route path="*" element={<Navigate replace to="/" />} />
                    </>
                )}
            </Routes>
        </>
    );
}