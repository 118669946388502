import { Link } from "react-router-dom";

export default function Footer() {
    return (
        <footer className="footer">
            <div className="footer__box">
                <div className="footer__corp">
                    <div className="footer__corp-name">(주)한국태양광거래소</div>
                    <div className="footer__corp-info">
                        <div className="footer__corp-item">대표이사 : 이일용</div>
                        <div className="footer__corp-item">사업자등록번호 : 373-87-03100</div>
                        <div className="footer__corp-item">TEL : 010-8812-9449  I  FAX : 042-867-3280</div>
                        <div className="footer__corp-item">주소 : 대전시 서구 둔지로13, 301호 (둔산동, 트라이얼스타 A동)</div>
                    </div>
                </div>
                <div className="footer__corp-customer">
                    <div className="footer__customer-title">고객센터</div>
                    <div className="footer__customer-number">010-8812-9449</div>
                    <div className="footer__customer-item">평일 09:30 ~ 17:30</div>
                    <div className="footer__customer-item">(주말/공휴일 제외)</div>
                </div>
            </div>
            <div className="fixed__footer">
                <Link
                    to={"https://blog.naver.com/liywr751008"}
                    target="_blank"
                    rel="noopener noreferrer">
                    <img className="fixed__footer-blog" src="/img/icon_blog.png" alt="blog" />
                </Link>
                <Link
                    to={"https://band.us/band/94819371"}
                    target="_blank"
                    rel="noopener noreferrer">
                    <img className="fixed__footer-band" src="/img/icon_band.png" alt="band" />
                </Link>
                <Link
                    to={"https://www.youtube.com/@%ED%95%9C%EA%B5%AD%ED%83%9C%EC%96%91%EA%B4%91%EA%B1%B0%EB%9E%98%EC%86%8C/featured"}
                    target="_blank"
                    rel="noopener noreferrer">
                    <img className="fixed__footer-youtube" src="/img/icon_youtube.png" alt="youtube" />
                </Link>
            </div>
        </footer>
    )
}