import { collection, doc, getDocs, orderBy, query } from "firebase/firestore";
import { db } from "firebaseApp";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export interface SalesProps {
    id?: string;
    address: string,
    rate: string,
    price: string,
    profit: string,
    loan: string,
    generationTime: string,
    period: string,
    startDate: string,
    rec: string,
    capacity: string,
    cost: string,
    expense: string,
    land: string,
    station: string,
    type: string,
    weight: string,
    module: string,
    inverter: string,
    structure: string,
    area: string,
    grade: string,
    createdAt: string,
    updatedAt?: string,
    uid: string,
    imageUrl?: string,
    imageUrls?: string[],
    completed?: boolean,
}

export default function SalesList() {

    const [sales, setSales] = useState<SalesProps[]>([]);

    const getSales = async () => {
        setSales([]);

        let salesRef = collection(db, "sales");
        let salesQuery = query(salesRef, orderBy("createdAt", "desc"));

        const datas = await getDocs(salesQuery);
        datas?.forEach((doc) => {
            const dataObj = { ...doc.data(), id: doc.id };
            setSales((prev) => [...prev, dataObj as SalesProps]);
        });
    }

    useEffect(() => {
        getSales();
    }, []);

    return (
        <>
            <div className="sales__list">
                {sales?.length > 0 ? sales?.map((sales) => (
                    <div key={sales?.id} className="sales__box">
                        <Link to={`/sales/${sales?.id}`}>
                            {sales?.imageUrls && (
                                <>
                                    {sales?.completed == true && (
                                        <img className="sales__completed" src="./img/completed.png" />
                                    )}
                                    <img className="sales__item-photo" src={sales?.imageUrls[0]} />
                                </>
                            )}
                            <div className="sales__address" >{sales?.address}</div>
                            <div className="sales__line" />
                            <div className="sales__info">
                                <div className="sales__price-box">
                                    <div>매도가격</div>
                                    <div className="sales__price">{sales?.price}</div>
                                </div>
                                <div className="sales__kw-box">
                                    <div>kw</div>
                                    <div className="sales__kw">{sales?.capacity}</div>
                                </div>
                            </div>
                        </Link>
                    </div>
                )) : ""
                }
            </div>
        </>
    )
}