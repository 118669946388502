import FaqForm from "components/FaqForm";
import Footer from "components/Footer";
import Header from "components/Header";

export default function FaqEditPage() {
    return (
        <>
            <Header />
            <div className="banner__menu">
                <img className="banner__menu-bg" src="/img/menu_bg.png" alt="banner" />
                <span>고객센터</span>
            </div>
            <FaqForm />
            <Footer />
        </>
    )
}