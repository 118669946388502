import Footer from "components/Footer";
import Header from "components/Header";
import RequestForm from "components/RequestForm";

export default function RoofPage() {
    return (
        <>
        <Header />
        <div className="banner__menu">
            <img className="banner__menu-bg" src="/img/menu_bg.png" alt="banner" />
            <span>지붕형 태양광</span>
        </div>
        <div className="banner__text-box">
            <div>빈 지붕으로 추가수익을,</div>
            <div>한국태양광거래소</div>
            <div className="banner__text-small">비어있는 공장 지붕으로, 직접설치 또는 임대 가능합니다.</div>
        </div>
        <RequestForm />
        <Footer />                
    </>
    )
}