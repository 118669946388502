import { useState } from "react";

const IMAGE_1_URL = "/img/sales.png";
const IMAGE_2_URL = "/img/sell.png";
const IMAGE_3_URL = "/img/buy.png";
const IMAGE_4_URL = "/img/roof.png";

export default function Carousel() {
    const [activeImage, setActiveImage] = useState(1);

    return (
        <>
            <div className="carousel">
                <ul className="carousel__slides">
                    <input type="radio" name="radio-buttons" id="img-1" checked={activeImage === 1} readOnly />
                    <li className="carousel__slide-container">
                        <div className="carousel__slide-img">
                            <img alt="scenery 1" src={IMAGE_1_URL} />
                        </div>
                        <div className="carousel__controls">
                            <label onClick={() => setActiveImage(4)} className="carousel__slide-prev">
                                <span>&lsaquo;</span>
                            </label>
                            <label onClick={() => setActiveImage(2)} className="carousel__slide-next">
                                <span>&rsaquo;</span>
                            </label>
                        </div>
                    </li>
                    <input type="radio" name="radio-buttons" id="img-2" checked={activeImage === 2} readOnly />
                    <li className="carousel__slide-container">
                        <div className="carousel__slide-img">
                            <img alt="scenery 2" src={IMAGE_2_URL} />
                        </div>
                        <div className="carousel__controls">
                            <label onClick={() => setActiveImage(1)} className="carousel__slide-prev">
                                <span>&lsaquo;</span>
                            </label>
                            <label onClick={() => setActiveImage(3)} className="carousel__slide-next">
                                <span>&rsaquo;</span>
                            </label>
                        </div>
                    </li>
                    <input type="radio" name="radio-buttons" id="img-3" checked={activeImage === 3} readOnly />
                    <li className="carousel__slide-container">
                        <div className="carousel__slide-img">
                            <img alt="scenery 3" src={IMAGE_3_URL} />
                        </div>
                        <div className="carousel__controls">
                            <label onClick={() => setActiveImage(2)} className="carousel__slide-prev">
                                <span>&lsaquo;</span>
                            </label>
                            <label onClick={() => setActiveImage(4)} className="carousel__slide-next">
                                <span>&rsaquo;</span>
                            </label>
                        </div>
                    </li>
                    <input type="radio" name="radio-buttons" id="img-4" checked={activeImage === 4} readOnly />
                    <li className="carousel__slide-container">
                        <div className="carousel__slide-img">
                            <img alt="scenery 4" src={IMAGE_4_URL} />
                        </div>
                        <div className="carousel__controls">
                            <label onClick={() => setActiveImage(3)} className="carousel__slide-prev">
                                <span>&lsaquo;</span>
                            </label>
                            <label onClick={() => setActiveImage(1)} className="carousel__slide-next">
                                <span>&rsaquo;</span>
                            </label>
                        </div>
                    </li>
                    <div className="carousel__dots">
                        <label onClick={() => setActiveImage(1)} className="carousel__dot" id="img-dot-1" />
                        <label onClick={() => setActiveImage(2)} className="carousel__dot" id="img-dot-2" />
                        <label onClick={() => setActiveImage(3)} className="carousel__dot" id="img-dot-3" />
                        <label onClick={() => setActiveImage(4)} className="carousel__dot" id="img-dot-4" />
                    </div>
                </ul>
            </div>
        </>
    )
}