import { Link } from "react-router-dom";
import { useContext } from "react";
import AuthContext from "context/AuthContext";

export default function Header() {
    const { user } = useContext(AuthContext);

    return (
        <header className="header">
            <div className="header__box">
                <Link to="/" className="header__logo-text">
                    <img src="/img/ci.png" className="header__logo" alt="logo" />
                </Link>
                <Link className="header__menu header__text-menu" to="/sales">발전소 매물</Link>
                <Link className="header__menu header__text-menu" to="/sell">발전소 팝니다</Link>
                <Link className="header__menu header__text-menu" to="/buy">발전소 삽니다</Link>
                <Link className="header__menu header__text-menu" to="/roof">지붕형 태양광</Link>
                <Link className="header__menu header__text-menu" to="/faq">고객센터</Link>
                {user?.uid ?
                    <Link className="header__menu header__text-menu" to="/profile">마이페이지</Link>
                    :
                    <Link className="header__menu header__text-menu" to="/login">마이페이지</Link>
                }
            </div>
        </header>
    )
}