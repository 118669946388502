import { addDoc, collection, doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "firebaseApp";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { FaqProps } from "./FaqList";
import AuthContext from "context/AuthContext";

export default function FaqForm() {
    const params = useParams();
    const [faq, setFaq] = useState<FaqProps | null>(null);
    const [name, setName] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [title, setTitle] = useState<string>("");
    const [content, setContent] = useState<string>("");    
    const navigate = useNavigate();

    const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        try {
            if (faq && faq?.id) {
                const faqRef = doc(db, "faq", faq?.id);
                await updateDoc(faqRef, {
                    name: name,
                    password: password,
                    title: title,
                    content: content,
                    updatedAt: new Date()?.toLocaleDateString("ko", {
                        hour: "2-digit",
                        minute: "2-digit",
                        second: "2-digit",
                    })
                });

                toast?.success("게시글을 수정했습니다.");
                navigate(`/faq/${faq?.id}`);

            } else {
                await addDoc(collection(db, "faq"), {
                    name: name,
                    password: password,
                    title: title,
                    content: content,
                    createdAt: new Date()?.toLocaleDateString("ko", {
                        hour: "2-digit",
                        minute: "2-digit",
                        second: "2-digit",
                    }),                    
                });

                toast?.success("게시글을 생성했습니다.");
                navigate("/faq/");
            }
        } catch (e: any) {
            toast?.error(e?.code);
        }
    }

    const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const {
            target: { name, value },
        } = e;

        if (name === "name") {
            setName(value);
        }

        if (name === "password") {
            setPassword(value);
        }

        if (name === "title") {
            setTitle(value);
        }

        if (name === "content") {
            setContent(value);
        }
    }

    const getFaq = async (id: string) => {
        if (id) {
            const docRef = doc(db, "faq", id);
            const docSnap = await getDoc(docRef);

            setFaq({ id: docSnap.id, ...(docSnap.data() as FaqProps) });

        }
    };

    useEffect(() => {
        if (params?.id) getFaq(params?.id);
    }, [params?.id]);

    useEffect(() => {
        if (faq) {
            setName(faq?.name);
            setTitle(faq?.title);
            setContent(faq?.content);
        }
    }, [faq]);

    return (
        <form onSubmit={onSubmit} className="form__faq">
            <div className="form__block">
                <label htmlFor="name">이름</label>
                <input type="text" name="name" id="name" required onChange={onChange} value={name} />
            </div>
            <div className="form__block">
                <label htmlFor="password">비밀번호</label>
                <input type="text" name="password" id="password" required onChange={onChange} value={password} />
            </div>
            <div className="form__block">
                <label htmlFor="title">제목</label>
                <input type="text" name="title" id="title" required onChange={onChange} value={title} />
            </div>
            <div className="form__block">
                <label htmlFor="content">내용</label>
                <textarea name="content" id="content" required onChange={onChange} value={content} />
            </div>
            <div className="form__block">
                <input type="submit" value={faq ? "수정완료" : "작성완료"} className="form__btn--submit" />
            </div>
        </form>
    )
}