import Footer from "components/Footer";
import Header from "components/Header";
import SalesList from "components/SalesList";
import { Link } from "react-router-dom";

export default function SalesPage() {
    return (
        <>
            <Header />
            <div className="banner__menu">
                <img className="banner__menu-bg" src="/img/menu_bg.png" />
                <span>발전소 매물</span>
            </div>
            <Link to="/sales/new">
                <input type="submit" value="+" className="sales__new-button" />
            </Link>
            <SalesList />
            <Footer />
        </>
    )
}