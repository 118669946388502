import { collection, getDocs, orderBy, query, where } from "firebase/firestore";
import { db } from "firebaseApp";
import { useEffect, useState } from "react";

export interface RequestProps {
    id?: string;
    category: string;
    name: string;
    contact: string;
    capacity: string;
    address: string;
    postcode: string;
    roadAddress: string;
    jibunAddress: string;
    detailAddress: string;
    price: string;
    createdAt: string;
}

export default function RequestList() {
    const [sellRequest, setSellRequest] = useState<RequestProps[]>([]);
    const [buyRequest, setBuyRequest] = useState<RequestProps[]>([]);
    const [roofRequest, setRoofRequest] = useState<RequestProps[]>([]);

    const getSellRequest = async () => {
        setSellRequest([]);

        let requestRef = collection(db, "request");
        let requestQuery = query(requestRef, 
            where("category", "==", "sell"), 
            orderBy("createdAt", "desc"));

        const datas = await getDocs(requestQuery);

        datas?.forEach((doc) => {
            const dataObj = { ...doc.data(), id: doc.id };
            setSellRequest((prev) => [...prev, dataObj as RequestProps]);
        });
    }

    const getBuyRequest = async () => {
        setBuyRequest([]);

        let requestRef = collection(db, "request");
        let requestQuery = query(requestRef, 
            where("category", "==", "buy"), 
            orderBy("createdAt", "desc"));

        const datas = await getDocs(requestQuery);

        datas?.forEach((doc) => {
            const dataObj = { ...doc.data(), id: doc.id };
            setBuyRequest((prev) => [...prev, dataObj as RequestProps]);
        });
    }

    const getRoofRequest = async () => {
        setRoofRequest([]);

        let requestRef = collection(db, "request");
        let requestQuery = query(requestRef, 
            where("category", "==", "roof"), 
            orderBy("createdAt", "desc"));

        const datas = await getDocs(requestQuery);

        datas?.forEach((doc) => {
            const dataObj = { ...doc.data(), id: doc.id };
            setRoofRequest((prev) => [...prev, dataObj as RequestProps]);
        });
    }

    useEffect(() => {
        getSellRequest();
        getBuyRequest();
        getRoofRequest();
    }, []);

    return (
        <>
            <div className="request__list">
                <p>1. 판매신청내역</p>
                <div className="request__sell-box">
                    <div className="request__index-title">번호</div>
                    <div className="request__name-title">신청자명</div>
                    <div className="request__contact-title">연락처</div>
                    <div className="request__capacity-title">발전소 용량</div>
                    <div className="request__address-title">발전소 주소</div>
                    <div className="request__price-title">매도 희망가격</div>
                </div>
                {sellRequest?.length > 0 ? sellRequest?.map((sellRequest, index) => (
                    <div key={sellRequest?.id} className="request__sell-list">
                        <div className="request__index">{index + 1}</div>
                        <div className="request__name">{sellRequest?.name}</div>
                        <div className="request__contact">{sellRequest?.contact}</div>
                        <div className="request__capacity">{sellRequest?.capacity}</div>
                        <div className="request__address">{sellRequest?.roadAddress + ", " + sellRequest?.detailAddress}</div>
                        <div className="request__price">{sellRequest?.price}</div>
                    </div>
                )) : (
                    <div className="request__no-request">신청내역이 없습니다.</div>
                )}

                <p>2. 구매신청내역</p>
                <div className="request__buy-box">
                    <div className="request__index-title">번호</div>
                    <div className="request__name-title">신청자명</div>
                    <div className="request__contact-title">연락처</div>
                    <div className="request__capacity-title">희망 발전소 용량</div>
                    <div className="request__address-title">희망 지역</div>
                    <div className="request__price-title">매수 희망가격</div>
                </div>
                {buyRequest?.length > 0 ? buyRequest?.map((buyRequest, index) => (
                    <div key={buyRequest?.id} className="request__buy-list">
                        <div className="request__index">{index + 1}</div>
                        <div className="request__name">{buyRequest?.name}</div>
                        <div className="request__contact">{buyRequest?.contact}</div>
                        <div className="request__capacity">{buyRequest?.capacity}</div>
                        <div className="request__address">{buyRequest?.address}</div>
                        <div className="request__price">{buyRequest?.price}</div>
                    </div>
                )) : (
                    <div className="request__no-request">신청내역이 없습니다.</div>
                )}

                <p>3. 지붕신청내역</p>
                <div className="request__roof-box">
                    <div className="request__index-title">번호</div>
                    <div className="request__name-title">신청자명</div>
                    <div className="request__contact-title">연락처</div>
                    <div className="request__address-title">건물 주소</div>
                </div>
                {roofRequest?.length > 0 ? roofRequest?.map((roofRequest, index) => (
                    <div key={roofRequest?.id} className="request__roof-list">
                        <div className="request__index">{index + 1}</div>
                        <div className="request__name">{roofRequest?.name}</div>
                        <div className="request__contact">{roofRequest?.contact}</div>
                        <div className="request__address">{roofRequest?.roadAddress + ", " + roofRequest?.detailAddress}</div>
                    </div>
                )) : (
                    <div className="request__no-request">신청내역이 없습니다.</div>
                )}                
            </div>
        </>
    )
}