import { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom"
import { SalesProps } from "./SalesList";
import { db } from "firebaseApp";
import { deleteDoc, doc, getDoc } from "firebase/firestore";
import Loader from "./Loader";
import { toast } from "react-toastify";
import AuthContext from "context/AuthContext";

export default function SalesDetail() {
    const [sales, setSales] = useState<SalesProps | null>(null);
    const params = useParams();
    const navigate = useNavigate();

    const { user } = useContext(AuthContext);

    const getSales = async (id: string) => {
        if (id) {
            const docRef = doc(db, "sales", id);
            const docSnap = await getDoc(docRef);

            setSales({ id: docSnap.id, ...(docSnap.data() as SalesProps) });
        }
    };

    const handleDelete = async (id: string) => {
        const confirm = window.confirm('해당 매물을 삭제하시겠습니까?')
        if (confirm && id) {
            await deleteDoc(doc(db, "sales", id));
            toast.success('매물을 삭제했습니다.')
            navigate("/sales");
        }
    };

    useEffect(() => {
        if (params?.id) getSales(params?.id);
    }, [params?.id]);

    return (
        <>
            <div className="banner__menu">
                <img className="banner__menu-bg" src="/img/menu_bg.png" />
                <span>발전소 검색</span>
            </div>
            <div className="sales__detail">
                {sales ? (
                    <>
                        <div className="sales__detail-title">{sales?.address}</div>
                        {user?.uid && (
                            <div className="sales__util-box">
                                <div className="sales__delete" role="presentation" onClick={() => handleDelete(sales.id as string)}>삭제</div>
                                <div className="sales__edit">
                                    <Link to={`/sales/edit/${sales?.id}`}>수정</Link>
                                </div>
                            </div>
                        )}

                        <div className="sales__detail-summary">
                            <div className="sales__summary-box sales__red">
                                <div className="sales__summary-tab">수익률</div>
                                <div className="sales__summary-pricevalue">{sales?.rate}</div>
                            </div>
                            <div className="sales__summary-box">
                                <div className="sales__summary-tab">매도가격</div>
                                <div className="sales__summary-value">{sales?.price}</div>
                            </div>
                            <div className="sales__summary-box">
                                <div className="sales__summary-tab">월평균수익</div>
                                <div className="sales__summary-value">{sales?.profit}</div>
                            </div>
                            <div className="sales__summary-box">
                                <div className="sales__summary-tab">대출금액</div>
                                <div className="sales__summary-value">{sales?.loan}</div>
                            </div>
                            <div className="sales__summary-box">
                                <div className="sales__summary-tab">평균발전시간</div>
                                <div className="sales__summary-value">{sales?.generationTime}</div>
                            </div>
                        </div>
                        <div className="sales__table">
                            <div className="sales__table-row">
                                <div className="sales__table-tab">발전소 연차</div>
                                <div className="sales__table-value">{sales?.period}</div>
                            </div>
                            <div className="sales__table-row">
                                <div className="sales__table-tab">발전시작연월</div>
                                <div className="sales__table-value">{sales?.startDate}</div>
                            </div>
                            <div className="sales__table-row">
                                <div className="sales__table-tab">REC거래형태</div>
                                <div className="sales__table-value">{sales?.rec}</div>
                            </div>
                            <div className="sales__table-row">
                                <div className="sales__table-tab">용량</div>
                                <div className="sales__table-value">{sales?.capacity}</div>
                            </div>
                            <div className="sales__table-row">
                                <div className="sales__table-tab">연간운영관리비</div>
                                <div className="sales__table-value">{sales?.cost}</div>
                            </div>
                            <div className="sales__table-row">
                                <div className="sales__table-tab">고정지출항목</div>
                                <div className="sales__table-value">{sales?.expense}</div>
                            </div>
                            <div className="sales__table-row">
                                <div className="sales__table-tab">토지지목</div>
                                <div className="sales__table-value">{sales?.land}</div>
                            </div>
                            <div className="sales__table-row">
                                <div className="sales__table-tab">계약발전소</div>
                                <div className="sales__table-value">{sales?.station}</div>
                            </div>
                            <div className="sales__table-row">
                                <div className="sales__table-tab">설치유형</div>
                                <div className="sales__table-value">{sales?.type}</div>
                            </div>
                            <div className="sales__table-row">
                                <div className="sales__table-tab">가중치</div>
                                <div className="sales__table-value">{sales?.weight}</div>
                            </div>
                            <div className="sales__table-row">
                                <div className="sales__table-tab">모듈</div>
                                <div className="sales__table-value">{sales?.module}</div>
                            </div>
                            <div className="sales__table-row">
                                <div className="sales__table-tab">인버터</div>
                                <div className="sales__table-value">{sales?.inverter}</div>
                            </div>
                            <div className="sales__table-row">
                                <div className="sales__table-tab">구조물타입/기초타입</div>
                                <div className="sales__table-value">{sales?.structure}</div>
                            </div>
                            <div className="sales__table-row">
                                <div className="sales__table-tab">토지면적/건축물면적</div>
                                <div className="sales__table-value">{sales?.area}</div>
                            </div>
                            <div className="sales__table-row">
                                <div className="sales__table-tab">발전소진단결과</div>
                                <div className="sales__table-value">{sales?.grade}</div>
                            </div>
                        </div>
                        <div className="sales__detail-title sales__detail-subtitle">발전소 사진</div>
                        <div className="sales__detail-phototable">
                            {sales?.imageUrls?.length && sales?.imageUrls?.length > 0 ? sales?.imageUrls?.map((image, index) => (
                                <div>
                                    {sales?.imageUrls && (
                                        <img className="sales__detail-photo" src={sales?.imageUrls[index]} alt="sales img" />
                                    )}
                                </div>
                            )) : ""}
                        </div>
                        <div className="sales__detail-button">
                            <Link to="/sales" className="sales__detail-backlist">목록으로 돌아가기</Link>
                        </div>
                    </>
                ) : (<Loader />)}
            </div>
        </>
    )
}