import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { app } from "firebaseApp";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export default function LoginForm() {
    const [error, setError] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const navigate = useNavigate();

    const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        try {
            const auth = getAuth(app);
            await signInWithEmailAndPassword(auth, email, password);

            toast.success("로그인에 성공했습니다.");
            navigate("/");
        } catch (error: any) {
            toast.error(error?.code);
        }
    }

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {
            target: { name, value },
        } = e;

        if (name === "email") {
            setEmail(value);

            const validRegex =
                /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i;

            if (!value?.match(validRegex)) {
                setError("이메일 형식이 올바르지 않습니다.");
            } else {
                setError("");
            }
        }

        if (name === "password") {
            setPassword(value);

            if (value?.length < 8) {
                setError("비밀번호는 8자리 이상 입력해주세요.")
            } else {
                setError("");
            }
        }
    }

    return (
        <form onSubmit={onSubmit} className="form__login">
            <h1 className="form__title">로그인</h1>
            <div className="form__block-login">
                <label htmlFor="email">아이디</label>
                <input
                    type="email"
                    name="email"
                    id="email"
                    required
                    onChange={onChange}
                    value={email}
                />
            </div>
            <div className="form__block-login">
                <label htmlFor="password">비밀번호</label>
                <input
                    type="password"
                    name="password"
                    id="password"
                    required
                    onChange={onChange}
                    value={password}
                />
            </div>
            {error && error?.length > 0 && (
                <div className="form__block">
                    <div className="form__error">{error}</div>
                </div>
            )}
            <div className="form__block-login">
                <input
                    type="submit"
                    value="로그인"
                    className="form__btn--submit" />
            </div>
        </form>
    )
}