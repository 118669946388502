import Footer from "components/Footer";
import Header from "components/Header";
import RequestForm from "components/RequestForm";

export default function SellPage() {
    return (
        <>
            <Header />
            <div className="banner__menu">
                <img className="banner__menu-bg" src="/img/menu_bg.png" alt="banner"/>
                <span>발전소 판매</span>
            </div>
            <div className="banner__text-box">
                <div>태양광 발전소 팔땐,</div>
                <div>한국태양광거래소</div>
                <div className="banner__text-small">전국단위 매수자 데이터베이스, 안전하고 성공적인 거래를 약속합니다.</div>
            </div>
            <RequestForm />
            <Footer />
        </>
    )
}