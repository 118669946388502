import AuthContext from "context/AuthContext";
import { collection } from "firebase/firestore";
import { getDownloadURL, ref, uploadString } from "firebase/storage";
import { storage } from "firebaseApp";
import { useContext, useState } from "react"
import { useNavigate } from "react-router-dom";
import { toast, useToastContainer } from "react-toastify";
import { v4 as uuidv4 } from "uuid";

export default function Test() {

    /*     const [sales, setSales] = useState<SalesProps[]>([]);
    
        const getSales = async () => {
            setSales([]);
    
            let salesRef = collection(db, "sales");
            let salesQuery = query(salesRef, orderBy("createdAt", "desc"));
    
            const datas = await getDocs(salesQuery);
            datas?.forEach((doc) => {
                const dataObj = { ...doc.data(), id: doc.id };
                setSales((prev) => [...prev, dataObj as SalesProps]);
            });
        } */

    const [imgFiles, setImgFiles] = useState<any[]>([]);

    const [imageFile, setImageFile] = useState<string | null>(null);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const { user } = useContext(AuthContext);
    const navigate = useNavigate();

    const handleFileUpload = (e: any) => {
        const {
            target: { files },
        } = e;        

        for (let i = 0; i < files?.length; i++) {
            const ff = files?.[i];
            const ffReader = new FileReader();
            ffReader?.readAsDataURL(ff);            

            setImgFiles((prev) => [...prev, files?.[i]]);
        }

        const file = files?.[0];
        const fileReader = new FileReader();
        fileReader?.readAsDataURL(file);

        fileReader.onloadend = (e: any) => {
            const { result } = e?.currentTarget;
            setImageFile(result);
        }
    };

    const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        setIsSubmitting(true);
        const key = `${user?.uid}/${uuidv4()}`;
        const storageRef = ref(storage, key);
        e.preventDefault();

        try {
            let imageUrl = "";
            if (imageFile) {
                const data = await uploadString(storageRef, imageFile, "data_url");
                imageUrl = await getDownloadURL(data?.ref);
            }
            setIsSubmitting(false);
            setImageFile(null);
        } catch (e: any) {
            toast?.error(e?.code);
        }
    }

    return (
        <form onSubmit={onSubmit} className="form__new">
            <div className="sales-form__image-area">
                <input type="file"
                    id="file-input"
                    name="file-input"
                    accept="image/*"
                    onChange={handleFileUpload}
                    multiple />
            </div>
            <div className="sales__detail-phototable">
                {imageFile && (
                    <img className="sales__detail-photo"
                        src={imageFile}
                    />
                )}
                {imgFiles?.length > 0 ? imgFiles?.map((img) => (
                    <>
                        <div key={img?.id}>
                            <img className="sales__detail-photo" src={img} />
                            {img?.name}
                        </div>                        
                    </>
                )) : ""}
            </div>

            <div className="sales__detail-button">
                <input type="submit" value={"매물 등록하기"}
                    className="form__btn--submit" disabled={isSubmitting} />
            </div>
        </form>
    )
}