import Header from "components/Header";
import Footer from "components/Footer";
import SalesList from "components/SalesList";
import Carousel from "components/Carousel";

export default function HomePage() {
    return (
        <div>
            <Header />
            <Carousel />
            <SalesList />
            <Footer />
        </div>
    )
}