"use client";

import { addDoc, collection, doc, getDoc } from "firebase/firestore";
import { db } from "firebaseApp";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { RequestProps } from "./RequestList";

declare global {
    interface Window {
        daum: any;
    }
}

interface DaumPostcodeData {
    userSelectedType: string;
    roadAddress: string;
    jibunAddress: string;
    bname: string;
    buildingName: string;
    apartment: string;
    zonecode: string;
}

export default function RequestForm() {

    const [extraAddr, setExtraAddr] = useState("");
    const [postcode, setPostcode] = useState("");
    const [roadAddress, setRoadAddress] = useState("");
    const [jibunAddress, setJibunAddress] = useState("");
    const [detailAddress, setDetailAddress] = useState("");
    const [addrType, setAddrType] = useState("");

    const popupDaumPostcode = () => {
        const screenWidth = window.screen.availWidth;
        const screenHeight = window.screen.availHeight;

        const popupWidth = 500;
        const popupHeight = 600;

        const left = (screenWidth - popupWidth) / 2;
        const top = (screenHeight - popupHeight) / 2;

        new window.daum.Postcode({
            oncomplete: function (data: DaumPostcodeData) {
                let addr = "";

                if (data.userSelectedType === "R") {
                    addr = data.roadAddress;
                    setAddrType("도로명주소");
                } else {
                    addr = data.jibunAddress;
                    setAddrType("지번주소");
                }

                let newExtraAddr = "";
                if (data.userSelectedType === "R") {
                    if (data.bname !== "" && /[동|로|가]$/g.test(data.bname)) {
                        newExtraAddr += data.bname;
                    }
                    if (data.buildingName !== "" && data.apartment === "Y") {
                        newExtraAddr +=
                            newExtraAddr !== ""
                                ? `, ${data.buildingName}`
                                : data.buildingName;
                    }
                    // 아파트가 아닌 빌라 등 건물이름 추가 ex) (갈산동, 우덕빌라)
                    if (data.buildingName !== "" && data.apartment === "N") {
                        newExtraAddr +=
                            newExtraAddr !== ""
                                ? `, ${data.buildingName}`
                                : data.buildingName;
                    }
                    if (newExtraAddr !== "") {
                        newExtraAddr = ` (${newExtraAddr})`;
                    }
                    setExtraAddr(newExtraAddr);
                } else {
                    setExtraAddr("");
                }

                setPostcode(data.zonecode);
                setRoadAddress(data.roadAddress);
                setJibunAddress(data.jibunAddress);

                // 주소 선택 시 자동으로 상세주소에 커서 위치시키기
                const detailAddressInput = document.getElementById("detailAddress");
                if (detailAddressInput) {
                    detailAddressInput.focus();
                }
            },
            // 팝업이 나타나는 위치 조정 가능
            // width: 500, // 팝업의 너비
            // height: 600, // 팝업의 높이
            // left: 500, // 팝업을 띄울 화면의 x 좌표
            // top: 300, // 팝업을 띄울 화면의 y 좌표

            width: popupWidth,
            height: popupHeight,
            left: left,
            top: top,
        })?.open();
    };

    // 우편번호 찾기 버튼 style
    const buttonStyle = {
        backgroundColor: "#007bff", // 버튼 배경색
        color: "#fff", // 버튼 텍스트 색상
        padding: "8px 16px", // 패딩 설정
        border: "none", // 테두리 없애기
        cursor: "pointer", // 커서 모양 변경
        borderRadius: "4px", // 버튼 테두리 둥글게 설정
    };

    const currentPath = window.location.pathname;
    const params = useParams();
    const [category, setCategory] = useState<string>("");
    const [request, setRequest] = useState<RequestProps | null>(null);
    const [name, setName] = useState<string>("");
    const [contact, setContact] = useState<string>("");
    const [capacity, setCapacity] = useState<string>("");
    const [address, setAddress] = useState<string>("");
    const [price, setPrice] = useState<string>("");
    const navigate = useNavigate();

    const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        try {
            await addDoc(collection(db, "request"), {
                category: category,
                name: name,
                contact: contact,
                capacity: capacity,
                address: address,
                postcode: postcode,
                roadAddress: roadAddress,
                jibunAddress: jibunAddress,
                detailAddress: detailAddress,
                price: price,
                createdAt: new Date()?.toLocaleDateString("ko", {
                    hour: "2-digit",
                    minute: "2-digit",
                    second: "2-digit",
                }),
            })
            toast?.success("문의글을 생성했습니다.");
            navigate("/");
        } catch (e: any) {
            toast?.error(e?.code);
        }
    }

    const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const {
            target: { name, value },
        } = e;

        if (currentPath === "/sell") {
            setCategory("sell");
        } else if (currentPath === "/buy") {
            setCategory("buy");
        } else if (currentPath === "/roof") {
            setCategory("roof");
        }

        if (name === "name") {
            setName(value);
        }

        if (name === "contact") {
            setContact(value);
        }

        if (name === "capacity") {
            setCapacity(value);
        }

        if (name === "address") {
            setAddress(value);
        }

        if (name === "postcode") {
            setPostcode(value);
        }

        if (name === "roadAddress") {
            setRoadAddress(value);
        }

        if (name === "jibunAddress") {
            setJibunAddress(value);
        }

        if (name === "detailAddress") {
            setDetailAddress(value);
        }

        if (name === "price") {
            setPrice(value);
        }
    }

    const getRequest = async (id: string) => {
        if (id) {
            const docRef = doc(db, "request", id);
            const docSnap = await getDoc(docRef);

            setRequest({ id: docSnap.id, ...(docSnap.data() as RequestProps) });

        }
    };

    useEffect(() => {
        if (params?.id) getRequest(params?.id);
    }, [params?.id]);

    useEffect(() => {
        if (request) {
            setCategory(request?.category)
            setName(request?.name);
            setContact(request?.contact);
            setCapacity(request?.capacity);
            setAddress(request?.address);
            setPostcode(request?.postcode);
            setJibunAddress(request?.jibunAddress);
            setRoadAddress(request?.roadAddress);
            setDetailAddress(request?.detailAddress);
            setPrice(request?.price);
        }
    }, [request]);

    return (
        <>
            {{
                "/sell":
                    <form onSubmit={onSubmit} className="form form__sell">
                        <div className="form__block">
                            <label htmlFor="name">신청자명</label>
                            <input type="text" name="name" id="name" required onChange={onChange}
                                value={name} placeholder="성함을 입력해주세요." />
                        </div>
                        <div className="form__block">
                            <label htmlFor="contact">연락처</label>
                            <input type="text" name="contact" id="contact" required onChange={onChange}
                                value={contact} placeholder="ex) 010-0000-0000" />
                        </div>
                        <div className="form__block">
                            <label htmlFor="capacity">발전소 용량</label>
                            <div className="form__block-unit">
                                <input type="text" name="capacity" id="capacity" required onChange={onChange}
                                    value={capacity} placeholder="ex) 99.99" />
                            </div>
                        </div>
                        <div className="form__block">
                            <label htmlFor="address">발전소 주소</label>
                            <div className="address__postcode">
                                <input
                                    type="text"
                                    name="postcode"
                                    id="postcode"
                                    value={postcode}
                                    readOnly
                                    placeholder="우편번호"
                                />
                                <input className="postcode__button" type="button" onClick={popupDaumPostcode} value="우편번호 찾기" />
                            </div>
                            <input
                                type="text"
                                name="roadAddress"
                                id="roadAddress"
                                value={addrType === "도로명주소" ? roadAddress : jibunAddress}
                                readOnly
                                placeholder="도로명주소"
                            />
                            <input
                                type="text"
                                name="detailAddress"
                                id="detailAddress"
                                value={detailAddress}
                                placeholder="상세 주소를 입력해주세요."
                                required
                                onChange={onChange}
                            />
                        </div>
                        <div className="form__block">
                            <label htmlFor="price">매도 희망가격</label>
                            <input type="text" name="price" id="price" required onChange={onChange}
                                value={price} placeholder="ex) 100,000,000" />
                        </div>
                        <div className="form__block">
                            <input type="submit" value="판매 신청하기" className="form__btn--submit" />
                        </div>
                    </form>,

                "/buy":
                    <form onSubmit={onSubmit} className="form form__buy">
                        <div className="form__block">
                            <label htmlFor="name">신청자명</label>
                            <input type="text" name="name" id="name" required onChange={onChange}
                                value={name} placeholder="성함을 입력해주세요." />
                        </div>
                        <div className="form__block">
                            <label htmlFor="contact">연락처</label>
                            <input type="tel" name="contact" id="contact" required onChange={onChange}
                                value={contact} placeholder="ex) 010-0000-0000" />
                        </div>
                        <div className="form__block">
                            <label htmlFor="capacity">희망 발전소 용량</label>
                            <div className="form__block-unit">
                                <input type="text" name="capacity" id="capacity" required onChange={onChange}
                                    value={capacity} placeholder="ex) 99.99" />
                            </div>
                        </div>
                        <div className="form__block">
                            <label htmlFor="address">희망 지역</label>
                            <input type="text" name="address" id="address" required onChange={onChange}
                                value={address} placeholder="희망 지역을 입력해주세요." />
                        </div>
                        <div className="form__block">
                            <label htmlFor="price">매수 희망가격</label>
                            <input type="text" name="price" id="price" required onChange={onChange}
                                value={price} placeholder="ex) 100,000,000" />
                        </div>
                        <div className="form__block">
                            <input type="submit" value="구매 신청하기" className="form__btn--submit" />
                        </div>
                    </form>,

                "/roof":
                    <form onSubmit={onSubmit} className="form form__roof">
                        <div className="form__block">
                            <label htmlFor="name">신청자명</label>
                            <input type="text" name="name" id="name" required onChange={onChange}
                                value={name} placeholder="성함을 입력해주세요." />
                        </div>
                        <div className="form__block">
                            <label htmlFor="contact">연락처</label>
                            <input type="tel" name="contact" id="contact" required onChange={onChange}
                                value={contact} placeholder="ex) 010-0000-0000" />
                        </div>
                        <div className="form__block">
                            <label htmlFor="address">(공장,건물) 주소</label>
                            <div className="address__postcode">
                                <input
                                    type="text"
                                    name="postcode"
                                    id="postcode"
                                    value={postcode}
                                    readOnly
                                    placeholder="우편번호"
                                />
                                <input className="postcode__button" type="button" onClick={popupDaumPostcode} value="우편번호 찾기" />
                            </div>
                            <input
                                type="text"
                                name="roadAddress"
                                id="roadAddress"
                                value={addrType === "도로명주소" ? roadAddress : jibunAddress}
                                readOnly
                                placeholder="도로명주소"
                            />
                            <input
                                type="text"
                                name="detailAddress"
                                id="detailAddress"
                                value={detailAddress}
                                placeholder="상세 주소를 입력해주세요."
                                required
                                onChange={onChange}
                            />
                        </div>
                        <div className="form__block">
                            <label>(공장,건물)주소만 입력하시면,</label>
                            <label>예상수익표와 제안서를 보내드립니다.</label>
                        </div>
                        <div className="form__block">
                            <input type="submit" value="견적 신청하기" className="form__btn--submit" />
                        </div>
                    </form>
            }[currentPath]}
        </>
    )
}