import Footer from "components/Footer";
import Header from "components/Header";
import Profile from "components/Profile";
import RequestList from "components/RequestList";

export default function ProfilePage() {
    return (
        <>
            <Header />
            <Profile />
            <RequestList />
            <Footer />
        </>
    )
}