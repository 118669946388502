import Footer from "components/Footer";
import Header from "components/Header";
import SalesForm from "components/SalesForm";

export default function SalesNewPage() {
    return (
        <>
            <Header />
            <SalesForm />
            <Footer />
        </>
    )
}