import { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { FaqProps } from "./FaqList";
import { db } from "firebaseApp";
import { deleteDoc, doc, getDoc } from "firebase/firestore";
import Loader from "./Loader";
import { toast } from "react-toastify";
import AuthContext from "context/AuthContext";

export default function FaqDetail() {
    const [faq, setFaq] = useState<FaqProps | null>(null);
    const params = useParams();
    const navigate = useNavigate();

    const { user } = useContext(AuthContext);

    const getFaq = async (id: string) => {
        if (id) {
            const docRef = doc(db, "faq", id);
            const docSnap = await getDoc(docRef);

            setFaq({ id: docSnap.id, ...docSnap.data() as FaqProps });
        }
    };

    const handleDelete = async () => {
        const confirm = window.confirm("해당 게시글을 삭제하시겠습니까?");
        if (confirm && faq && faq.id) {
            await deleteDoc(doc(db, "faq", faq.id));
            toast.success("게시글을 삭제했습니다.");
            navigate("/faq/");
        }
    }

    useEffect(() => {
        if (params?.id) getFaq(params?.id);
    }, [params?.id]);

    return (
        <>
            <div className="faq__detail">
                {faq ? ('') : <Loader />}
                <div className="faq__detail-box">
                    <div className="faq__title">{faq?.title}</div>
                    <div className="faq__profile-box">
                        <div className="faq__profile" />
                        <div className="faq__author-name">{faq?.name}</div>
                        <div className="faq__date">{faq?.createdAt}</div>
                    </div>
                    {user?.uid && (
                        <div className="faq__utils-box">
                            <div className="faq__delete" role="presentation" onClick={handleDelete}>삭제</div>
                            <div className="faq__edit">
                                <Link to={`/faq/edit/${faq?.id}`}>수정</Link>
                            </div>
                        </div>
                    )}
                    <div className="faq__text faq__text--pre-wrap">{faq?.content}</div>
                </div>
            </div>
        </>
    )
}