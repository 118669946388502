import Footer from "components/Footer";
import Header from "components/Header";
import SalesDetail from "components/SalesDetail";

export default function SalesDetailPage() {
    return (
        <>
            <Header />
            <SalesDetail />
            <Footer />
        </>
    )
}