import { addDoc, arrayUnion, collection, doc, getDoc, updateDoc } from "firebase/firestore";
import { db, storage } from "firebaseApp";
import React, { useContext, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { SalesProps } from "./SalesList";
import AuthContext from "context/AuthContext";
import { v4 as uuidv4 } from "uuid";
import { getDownloadURL, ref, uploadString } from "firebase/storage";

export default function SalesForm() {

    const params = useParams();
    const [sales, setSales] = useState<SalesProps | null>(null);

    const [imageFile, setImageFile] = useState<string | null>(null);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const imageUrls = useState<string[]>([]);

    const [address, setAddress] = useState<string>("");                 // 주소
    const [rate, setRate] = useState<string>("");                       // 수익률
    const [price, setPrice] = useState<string>("");                     // 매도가격
    const [profit, setProfit] = useState<string>("");                   // 월평균수익
    const [loan, setLoan] = useState<string>("");                       // 대출금액
    const [generationTime, setGenerationTime] = useState<string>("");   // 평균발전시간
    const [period, setPeriod] = useState<string>("");                   // 발전소 연차
    const [startDate, setStartDate] = useState<string>("");             // 발전시작연월
    const [rec, setRec] = useState<string>("");                         // REC 거래형태
    const [capacity, setCapacity] = useState<string>("");               // 용량
    const [cost, setCost] = useState<string>("");                       // 연간운영관리비
    const [expense, setExpense] = useState<string>("");                 // 고정지출항목
    const [land, setLand] = useState<string>("");                       // 토지지목
    const [station, setStation] = useState<string>("");                 // 계약발전소
    const [type, setType] = useState<string>("");                       // 설치유형
    const [weight, setWeight] = useState<string>("");                   // 가중치
    const [module, setModule] = useState<string>("");                   // 모듈
    const [inverter, setInverter] = useState<string>("");               // 인버터
    const [structure, setStructure] = useState<string>("");             // 구조물타입/기초타입
    const [area, setArea] = useState<string>("");                       // 토지면적/건축물면적
    const [grade, setGrade] = useState<string>("");                     // 발전소 진단결과

    const { user } = useContext(AuthContext);
    const navigate = useNavigate();

    const handleFileUpload = (e: any) => {
        const {
            target: { files },
        } = e;

        const file = files?.[0];
        const fileReader = new FileReader();
        fileReader?.readAsDataURL(file);

        fileReader.onloadend = (e: any) => {
            const { result } = e?.currentTarget;
            setImageFile(result);
        }
    };

    const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        setIsSubmitting(true);
        const key = `${user?.uid}/${uuidv4()}`;
        const storageRef = ref(storage, key);
        e.preventDefault();

        try {
            let imageUrl = "";
            if (imageFile) {
                const data = await uploadString(storageRef, imageFile, "data_url");
                imageUrl = await getDownloadURL(data?.ref);
            }

            if (sales && sales.id && imageUrl) {
                const salesRef = doc(db, "sales", sales?.id);
                await updateDoc(salesRef, {
                    address: address,
                    rate: rate,
                    price: price,
                    profit: profit,
                    loan: loan,
                    generationTime: generationTime,
                    period: period,
                    startDate: startDate,
                    rec: rec,
                    capacity: capacity,
                    cost: cost,
                    expense: expense,
                    land: land,
                    station: station,
                    type: type,
                    weight: weight,
                    module: module,
                    inverter: inverter,
                    structure: structure,
                    area: area,
                    grade: grade,
                    imageUrls: arrayUnion(imageUrl),
                    updatedAt: new Date()?.toLocaleDateString("ko", {
                        hour: "2-digit",
                        minute: "2-digit",
                        second: "2-digit",
                    })
                });

                toast?.success("매물을 수정했습니다.");
                setIsSubmitting(false);
                setImageFile(null);
                navigate(`/sales/${sales.id}`);

            } else if (sales && sales.id) {
                const salesRef = doc(db, "sales", sales?.id);
                await updateDoc(salesRef, {
                    address: address,
                    rate: rate,
                    price: price,
                    profit: profit,
                    loan: loan,
                    generationTime: generationTime,
                    period: period,
                    startDate: startDate,
                    rec: rec,
                    capacity: capacity,
                    cost: cost,
                    expense: expense,
                    land: land,
                    station: station,
                    type: type,
                    weight: weight,
                    module: module,
                    inverter: inverter,
                    structure: structure,
                    area: area,
                    grade: grade,                    
                    updatedAt: new Date()?.toLocaleDateString("ko", {
                        hour: "2-digit",
                        minute: "2-digit",
                        second: "2-digit",
                    })
                });

                toast?.success("매물을 수정했습니다.");
                setIsSubmitting(false);
                setImageFile(null);
                navigate(`/sales/${sales.id}`);
            } else {
                await addDoc(collection(db, "sales"), {
                    address: address,
                    rate: rate,
                    price: price,
                    profit: profit,
                    loan: loan,
                    generationTime: generationTime,
                    period: period,
                    startDate: startDate,
                    rec: rec,
                    capacity: capacity,
                    cost: cost,
                    expense: expense,
                    land: land,
                    station: station,
                    type: type,
                    weight: weight,
                    module: module,
                    inverter: inverter,
                    structure: structure,
                    area: area,
                    grade: grade,
                    imageUrls: arrayUnion(imageUrl),
                    createdAt: new Date()?.toLocaleDateString("ko", {
                        hour: "2-digit",
                        minute: "2-digit",
                        second: "2-digit",
                    }),
                    uid: user?.uid,
                })

                toast.success("매물을 등록했습니다.");
                setIsSubmitting(false);
                navigate("/");
            }
        } catch (e: any) {
            toast?.error(e?.code);
        }
    }

    const onChange = (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        const {
            target: { name, value },
        } = e;

        if (name === "address") { setAddress(value); }
        if (name === "rate") { setRate(value); }
        if (name === "price") { setPrice(value); }
        if (name === "profit") { setProfit(value); }
        if (name === "loan") { setLoan(value); }
        if (name === "generationTime") { setGenerationTime(value); }
        if (name === "period") { setPeriod(value); }
        if (name === "startDate") { setStartDate(value); }
        if (name === "rec") { setRec(value); }
        if (name === "capacity") { setCapacity(value); }
        if (name === "cost") { setCost(value); }
        if (name === "expense") { setExpense(value); }
        if (name === "land") { setLand(value); }
        if (name === "station") { setStation(value); }
        if (name === "type") { setType(value); }
        if (name === "weight") { setWeight(value); }
        if (name === "module") { setModule(value); }
        if (name === "inverter") { setInverter(value); }
        if (name === "structure") { setStructure(value); }
        if (name === "area") { setArea(value); }
        if (name === "grade") { setGrade(value); }
    }

    const getSales = async (id: string) => {
        if (id) {
            const docRef = doc(db, "sales", id);
            const docSnap = await getDoc(docRef);

            setSales({ id: docSnap.id, ...(docSnap.data() as SalesProps) });
        }
    };

    useEffect(() => {
        if (params?.id) getSales(params?.id);
    }, [params?.id]);

    useEffect(() => {
        if (sales) {
            setAddress(sales?.address)
            setRate(sales?.rate)
            setPrice(sales?.price)
            setProfit(sales?.profit)
            setLoan(sales?.loan)
            setGenerationTime(sales?.generationTime)
            setPeriod(sales?.period)
            setStartDate(sales?.startDate)
            setRec(sales?.rec)
            setCapacity(sales?.capacity)
            setCost(sales?.cost)
            setExpense(sales?.expense)
            setLand(sales?.land)
            setStation(sales?.station)
            setType(sales?.type)
            setWeight(sales?.weight)
            setModule(sales?.module)
            setInverter(sales?.inverter)
            setStructure(sales?.structure)
            setArea(sales?.area)
            setGrade(sales?.grade)
        }
    }, [sales]);

    return (
        <>
            <div className="banner__menu">
                <img className="banner__menu-bg" src="/img/menu_bg.png" />
                <span>발전소 매물 등록</span>
            </div>
            <form onSubmit={onSubmit} className="form__new">
                <div className="sales__table">
                    <div className="sales__table-row">
                        <div className="sales__table-tab">주소</div>
                        <input type="text" name="address" id="address" onChange={onChange} className="sales__table-input" placeholder="ex) 대전시 서구 둔산동" required value={address} />
                    </div>
                    <div className="sales__table-row">
                        <div className="sales__table-tab">수익률</div>
                        <input type="text" name="rate" id="rate" onChange={onChange} className="sales__table-input" placeholder="ex) 10% " value={rate} />
                    </div>
                    <div className="sales__table-row">
                        <div className="sales__table-tab">매도가격</div>
                        <input type="text" name="price" id="price" onChange={onChange} className="sales__table-input" placeholder="ex) 1억 2천만원" required value={price} />
                    </div>
                    <div className="sales__table-row">
                        <div className="sales__table-tab">월평균수익</div>
                        <input type="text" name="profit" id="profit" onChange={onChange} className="sales__table-input" placeholder="ex) 300만원 " value={profit} />
                    </div>
                    <div className="sales__table-row">
                        <div className="sales__table-tab">대출금액</div>
                        <input type="text" name="loan" id="loan" onChange={onChange} className="sales__table-input" placeholder="ex) 1억 가능 " value={loan} />
                    </div>
                    <div className="sales__table-row">
                        <div className="sales__table-tab">평균발전시간</div>
                        <input type="text" name="generationTime" id="generationTime" onChange={onChange} className="sales__table-input" placeholder="ex) 3.6시간 " value={generationTime} />
                    </div>
                    <div className="sales__table-row">
                        <div className="sales__table-tab">발전소 연차</div>
                        <input type="text" name="period" id="period" onChange={onChange} className="sales__table-input" placeholder="ex) 2년차" value={period} />
                    </div>
                    <div className="sales__table-row">
                        <div className="sales__table-tab">발전시작연월</div>
                        <input type="text" name="startDate" id="startDate" onChange={onChange} className="sales__table-input" placeholder="ex) 2022년 3월 시작" value={startDate} />
                    </div>
                    <div className="sales__table-row">
                        <div className="sales__table-tab">REC거래형태</div>
                        <input type="text" name="rec" id="rec" onChange={onChange} className="sales__table-input" placeholder="ex) 일반 장기계약 170원" value={rec} />
                    </div>
                    <div className="sales__table-row">
                        <div className="sales__table-tab">용량</div>
                        <input type="text" name="capacity" id="capacity" onChange={onChange} className="sales__table-input" placeholder="ex) 99.9kw" required value={capacity} />
                    </div>
                    <div className="sales__table-row">
                        <div className="sales__table-tab">연간운영관리비</div>
                        <input type="text" name="cost" id="cost" onChange={onChange} className="sales__table-input" placeholder="ex) 15만원" value={cost} />
                    </div>
                    <div className="sales__table-row">
                        <div className="sales__table-tab">고정지출항목(모니터링)</div>
                        <input type="text" name="expense" id="expense" onChange={onChange} className="sales__table-input" placeholder="ex) 인터넷, cctv 등 합계 24,000원" value={expense} />
                    </div>
                    <div className="sales__table-row">
                        <div className="sales__table-tab">토지지목</div>
                        <input type="text" name="land" id="land" onChange={onChange} className="sales__table-input" placeholder="ex) 잡종지" value={land} />
                    </div>
                    <div className="sales__table-row">
                        <div className="sales__table-tab">계약발전소</div>
                        <input type="text" name="station" id="station" onChange={onChange} className="sales__table-input" placeholder="ex) 한국남부발전소" value={station} />
                    </div>
                    <div className="sales__table-row">
                        <div className="sales__table-tab">설치유형</div>
                        <input type="text" name="type" id="type" onChange={onChange} className="sales__table-input" placeholder="ex) 고정형" value={type} />
                    </div>
                    <div className="sales__table-row">
                        <div className="sales__table-tab">가중치</div>
                        <input type="text" name="weight" id="weight" onChange={onChange} className="sales__table-input" placeholder="ex) 1.2" value={weight} />
                    </div>
                    <div className="sales__table-row">
                        <div className="sales__table-tab">모듈</div>
                        <input type="text" name="module" id="module" onChange={onChange} className="sales__table-input" placeholder="ex) 한화 QCELLS L-G 415W" value={module} />
                    </div>
                    <div className="sales__table-row">
                        <div className="sales__table-tab">인버터</div>
                        <input type="text" name="inverter" id="inverter" onChange={onChange} className="sales__table-input" placeholder="ex) 썬그루 * 2대" value={inverter} />
                    </div>
                    <div className="sales__table-row">
                        <div className="sales__table-tab">구조물타입/기초타입</div>
                        <input type="text" name="structure" id="structure" onChange={onChange} className="sales__table-input" placeholder="ex) 줄기초" value={structure} />
                    </div>
                    <div className="sales__table-row">
                        <div className="sales__table-tab">토지면적/건축물면적</div>
                        <input type="text" name="area" id="area" onChange={onChange} className="sales__table-input" placeholder="ex) 약 400평" value={area} />
                    </div>
                    <div className="sales__table-row">
                        <div className="sales__table-tab">발전소진단결과</div>
                        <input type="text" name="grade" id="grade" onChange={onChange} className="sales__table-input" placeholder="ex) 중상" value={grade} />
                    </div>
                </div>
                <div className="sales-form__image-area">
                    <input type="file" id="file-input" name="file-input" accept="image/*" onChange={handleFileUpload} multiple />
                </div>
                <div className="sales__detail-phototable">
                    {imageFile && (
                        <img className="sales__detail-photo" src={imageFile} />
                    )}
                </div>

                <div className="sales__detail-button">
                    <input type="submit" value={sales ? "매물 수정하기" : "매물 등록하기"} className="form__btn--submit" disabled={isSubmitting} />
                </div>
            </form>
        </>
    )
}