import { collection, doc, getDocs, orderBy, query } from "firebase/firestore";
import { db } from "firebaseApp";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export interface FaqProps {
    id?: string;
    name: string;
    password: string;
    title: string;
    content: string;
    createdAt: string;
    updatedAt?: string;
    uid: string;
}

export default function FaqList() {
    const [faq, setFaq] = useState<FaqProps[]>([]);

    const getFaq = async () => {
        setFaq([]);

        let faqRef = collection(db, "faq");
        let faqQuery = query(faqRef, orderBy("createdAt", "desc"));

        const datas = await getDocs(faqQuery);

        datas?.forEach((doc) => {
            const dataObj = { ...doc.data(), id: doc.id };
            setFaq((prev) => [...prev, dataObj as FaqProps]);
        });
    }

    useEffect(() => {
        getFaq();
    }, []);    

    return (
        <>
            <div className="faq__list">
                <div className="faq__box">
                    <div className="faq__index faq__index-title">번호</div>
                    <div className="faq__title-title">제목</div>
                    <div className="faq__author-name-title">작성자</div>
                    <div className="faq__date-title">날짜</div>
                </div>
                {faq?.length > 0 ? faq?.map((faq, index) => (
                    <div key={faq?.id} className="faq__box">
                        <div className="faq__index">{index}</div>
                        <Link to={`/faq/${faq?.id}`} className="faq__title">{faq?.title}</Link>
                        <div className="faq__author-name">{faq?.name}</div>
                        <div className="faq__date">{faq?.createdAt}</div>
                    </div>
                )) : (
                    <div className="faq__no-faq">게시글이 없습니다.</div>
                )}
                <Link to="/faq/new">
                    <input type="submit" value="글쓰기" className="faq__new-button" />
                </Link>
            </div>
        </>
    )
}